import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "../core";
import {
  AccountSubscriptionDetailsDto,
  AccountSubscriptionGetDto,
  AccountSubscriptionPostDto,
  NotificationGetDto,
  SubscriptionGetDto
} from "../dto";

@Injectable({
  providedIn: "root"
})
export class CompanyService {
  protected serviceUrl = "Company";

  constructor(private api: ApiService) {}

  getNotification(): Observable<NotificationGetDto> {
    return this.api.get(`${this.serviceUrl}/notifications`);
  }

  getSubscription(): Observable<SubscriptionGetDto> {
    return this.api.get(`${this.serviceUrl}/subscription`);
  }

  getAccountSubscription(): Observable<AccountSubscriptionGetDto> {
    return this.api.get(`${this.serviceUrl}/accountSubscription`);
  }

  updateAccountSubscription(data: AccountSubscriptionPostDto): Observable<AccountSubscriptionGetDto> {
    return this.api.post(`${this.serviceUrl}/accountSubscription`, data);
  }

  getAccountSubscriptionContext(accountId: number): Observable<AccountSubscriptionDetailsDto> {
    return this.api.get(`${this.serviceUrl}/subscription-context/${accountId}`);
  }

  getCurrentSubscriptionContext(): Observable<AccountSubscriptionDetailsDto> {
    return this.api.get(`${this.serviceUrl}/subscription-context`);
  }
}
