export interface MediaContentDto {
  id: number;
  name: string;
  title: string;
  mediaContentType: MediaContentType;
  configuration: MediaContentConfigurationDto;
  digitalAssets: DigitalAssetGetDto[];
}

export interface DigitalAssetGetDto {
  id?: number;
  mediaContentId?: number;
  name?: string;
  content?: string;
  title?: string;
  contentUrl?: string;
  digitalAssetType?: DigitalAssetType;
  contentType?: string;
  configuration?: DigitalAssetConfigurationDto;
}

export interface MediaContentConfigurationDto {
  defaultParameters: KeyValueDto[];
}

export interface DigitalAssetConfigurationDto {
  defaultParameters: KeyValueDto[];
  screenSize: DigitalAssetScreenSize;
}

export enum MediaContentType {
  CfsTemplate = "CfsTemplate",
  QrCodeTemplate = "QrCodeTemplate",
  CfsQrCodeTemplate = "CfsQrCodeTemplate"
}

export interface KeyValueDto {
  key: string;
  value: string;
}

export enum DigitalAssetType {
  Lottie = "Lottie",
  Html = "Html"
}

export enum DigitalAssetScreenSize {
  Compact = "Compact",
  Full = "Full",
  Vertical = "Vertical",
  Tablet = "Tablet"
}

export interface KeyValueAsset {
  [key: string]: string;
}
