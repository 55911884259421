export enum Permission {
  Dashboard = "Dashboard",
  Developers = "Developers",
  MarketingCenter = "MarketingCenter",
  MarketingCampaigns = "MarketingCampaigns",
  MarketingTools = "MarketingTools",
  MarketingAppsSettings = "MarketingAppsSettings",
  Templates = "Templates",
  CFSTerminals = "CFSTerminals",
  AdvancedTools = "AdvancedTools", //manager
  URLsAndQRCodes = "URLsAndQRCodes",
  AppsShortURLs = "AppsShortURLs",
  Customers = "Customers",
  CustomShortURLs = "CustomShortURLs",
  PixelTags = "PixelTags",
  BulkLinks = "BulkLinks",
  OutboundMarketing = "OutboundMarketing", //manager
  PricingPlanUpgrade = "PricingPlanUpgrade",
  POSRules = "POSRules", //manager & location-manager
  POSActivityReport = "POSActivityReport", //manager
  POSIntegration = "POSIntegration",
  POSSettings = "POSSettings", //manager
  POSReceiptSettings = "POSReceiptSettings", //manager
  PrintReceipts = "PrintReceipts",
  ReportingAnalytics = "ReportingAnalytics", //manager
  ThirdPartyAnalytics = "ThirdPartyAnalytics",
  CustomTransactionReport = "CustomTransactionReport", //manager
  Contacts = "Contacts",
  Products = "Products",
  CompanySettings = "CompanySettings",
  ManagerSettings = "ManagerSettings",
  Locations = "Locations",
  Devices = "Devices",
  Distributions = "Distributions",
  Users = "Users",
  MyBrand = "MyBrand",
  CompanyConnectors = "CompanyConnectors", //manager
  DigitalScreens = "DigitalScreens",
  DigitalScreensDashboard = "DigitalScreensDashboard",
  LoyaltyProgram = "LoyaltyProgram",
  LoyaltyReporting = "LoyaltyReporting",
  ShowOnPlanChooser = "ShowOnPlanChooser",

  // Action permissions:
  CanCreateBulks = "CanCreateBulks",
  CanDeleteBulks = "CanDeleteBulks",
  CanViewBulks = "CanViewBulks"
}

export const permissionByRoute: Record<string, Permission> = {
  "/": Permission.Dashboard,
  "/dashboard": Permission.Dashboard,
  "/developers": Permission.Developers,
  // "": Permission.MarketingTools, // main menu section (no route)
  // "": Permission.MarketingCampaigns, // todo: tbd
  "/manager/app-settings": Permission.MarketingAppsSettings,
  "/marketing-apps-settings": Permission.MarketingAppsSettings,
  "/template": Permission.Templates,
  "/template/preview": Permission.Templates,
  "/template/preview-global": Permission.Templates,
  "/template/print": Permission.Templates,
  "/template/carousel-preview": Permission.Templates,
  "/manager/templates": Permission.Templates,
  "/manager/locations/cfsterminals": Permission.CFSTerminals,
  "/manager/external-features/AdvancedMarketingTools": Permission.AdvancedTools,
  // "": Permission.DigitalScreens, // main menu section (no route)
  // "": Permission.DigitalScreensDashboard, // no route
  // "": Permission.URLsAndQRCodes, // main menu section (no route)
  "/manager/short-urls": Permission.AppsShortURLs,
  "/manager/short-urls/apps": Permission.AppsShortURLs,
  "/manager/short-urls/custom": Permission.CustomShortURLs,
  "/manager/pixel-tags": Permission.PixelTags,
  "/manager/bulk-links": Permission.BulkLinks,
  "/manager/external-features/OutboundMarketing": Permission.OutboundMarketing,
  "/manager/pos-rules": Permission.POSRules,
  "/manager/pos-rules/add": Permission.POSRules,
  "/manager/pos-rules/edit": Permission.POSRules,
  "/manager/pos-activity-report": Permission.POSActivityReport,
  // "": Permission.POSIntegration, // main menu section (no route)
  "/manager/pos-configuration": Permission.POSSettings,
  "/manager/pos-receipt-settings": Permission.POSReceiptSettings,
  "/manager/pricing-plan": Permission.PricingPlanUpgrade,
  // "manager/external-features/ReportingAnalytics": Permission.ReportingAnalytics, // main menu section (no route)
  "/manager/external-features/ReportingAnalytics": Permission.ThirdPartyAnalytics,
  // "": Permission.LoyaltyProgram, // main menu section (no route)
  "/manager/external-features/LoyaltyReporting": Permission.LoyaltyReporting,
  "/manager/external-features/Contacts": Permission.Contacts,
  "/manager/customers": Permission.Customers,
  "/manager/customers/customer-list": Permission.Customers,
  "/manager/customers/customer": Permission.Customers,
  "/manager/product/add": Permission.Products,
  "/manager/product/update": Permission.Products,
  "/manager/product/location": Permission.Products,
  "/manager/products/location/all": Permission.Products,
  "/manager/external-features/CustomTransactionReport": Permission.CustomTransactionReport,
  "/manager/settings": Permission.ManagerSettings,
  "/distributions": Permission.Distributions,
  "/manager/locations": Permission.Locations,
  "/manager/devices": Permission.Devices,
  "/manager/users": Permission.Users,
  "/manager/my-brand": Permission.MyBrand
  // "": Permission.CompanyConnectors, // no route
  // "": Permission.PrintReceipts, // todo: tbd
  // "": Permission.ShowOnPlanChooser // no route (only onboarding feature)
};
