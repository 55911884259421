import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TranslocoModule } from "@ngneat/transloco";

type Item = { value: number | string; label: string };

@Component({
  selector: "app-toggle-group-with-label",
  templateUrl: "./toggle-group-with-label.component.html",
  styleUrls: ["./toggle-group-with-label.component.scss"],
  standalone: true,
  imports: [CommonModule, TranslocoModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToggleGroupWithLabelComponent implements OnInit {
  @Input() label = "";
  @Input() isDisabled = false;
  @Input() groupClasslist = "btn-group";
  @Input() btnClasslist = "min-100";
  @Input() items: Item[] = [];
  @Input() default: Item | null = null;

  @Input() set defaultValue(v: string | number) {
    this.activeItem = this.items.find(({ value }) => value === v) || null;
  }

  @Output() change = new EventEmitter<Item>();

  activeItem: Item | null = null;

  ngOnInit(): void {
    if (!this.activeItem && this.items.length) {
      this.activeItem = this.items[0];
    }
  }

  onClick(item: Item): void {
    if (this.activeItem !== item) {
      this.change.emit(item);
    }
    this.activeItem = item;
  }
}
