 <div
    class="template__wrapper"
    [class.test-mode-template]="isTestMode"
    (click)="onTemplateClick()">
    <div class="template" [class.vertical-centered]="forPrint">
      <lib-cfs-template-preview
        [template]="template"
        [index]="index"
        [previewType]="previewType"
        [isPrintView]="forPrint"
        [fullMediaConfig]="fullMediaConfig"
        [fullScreen]="true"
        [responsive]="responsive"
        [isLowResolutionScreen]="isLowResolutionScreen"
        (printReady)="print.emit()"
        (ready)="ready.emit()">
      </lib-cfs-template-preview>
    </div>
  </div>

<div *ngIf="isTestMode" class="test-mode-info">
  <span *ngIf="index >= 0">Index: {{ index }}; </span>
  <span *ngIf="title">Template name: {{ title }}; </span>
  <span *ngIf="contentDuration">Content duration: {{ contentDuration / 1000 }}; </span>
  <span *ngIf="displayTime">Display Time: {{ displayTime / 1000 }}; </span>
</div>
