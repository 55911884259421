export enum PlayerState {
  UNSTARTED = -1,
  ENDED = 0,
  PLAYING = 1,
  PAUSED = 2,
  BUFFERING = 3,
  CUED = 5
}

export enum AutoHide {
  /**
   * Controls are visible throughout the video
   */
  AlwaysVisible = 0,

  /**
   * Progress bar and player controls slide out of view after a couple of seconds.
   */
  HideAllControls = 1,

  /**
   * Progress bar fades out while the player controls remain visible.
   */
  HideProgressBar = 2
}

/**
 * Whether to autoplay the video.
 */
export enum AutoPlay {
  /**
   * Video does not autoplay.
   */
  NoAutoPlay = 0,

  /**
   * Video will autoplay when loaded.
   */
  AutoPlay = 1
}

export enum Controls {
  /**
   * Player controls do not display.
   */
  Hide = 0,

  /**
   * Player controls display.
   */
  ShowLoadPlayer = 1,

  /**
   * Player controls display after a delay.
   */
  ShowDelayLoadPlayer = 2
}

/**
 * Whether to allow keyboard controls.
 */
export enum KeyboardControls {
  /**
   * Keyboard controls are enabled.
   */
  Enable = 0,

  /**
   * Keyboard controls are disabled.
   */
  Disable = 1
}

export enum FullscreenButton {
  /**
   * The full screen button is hidden.
   */
  Hide = 0,

  /**
   * The full screen button is visible.
   */
  Show = 1
}

/**
 * Whether to show video annotations.
 */
export enum IvLoadPolicy {
  /**
   * Video annotations will be shown.
   */
  Show = 1,

  /**
   * Video annotations will not be shown.
   */
  Hide = 3
}

export enum Loop {
  /**
   * Video or playlist will be played only once.
   */
  SinglePlay = 0,

  /**
   * Video or playlist will be played over and over again.
   */
  Loop = 1
}

/**
 * Whether or not to start the video muted. Some browsers require this set to 1 for autoplay to work (e.g. Chrome).
 */
export enum Mute {
  /**
   * Player will start not muted, with sound
   */
  NotMuted = 0,

  /**
   * Player will start muted
   */
  Muted = 1
}

export enum RelatedVideos {
  /**
   * Hide related videos after playback is complete.
   */
  Hide = 0,

  /**
   * Show related videos after playback is complete.
   */
  Show = 1
}
