import { Inject, Injectable } from "@angular/core";
import { CORE_KIT_CONFIG_TOKEN, CoreKitConfig } from "core-kit";

@Injectable({
  providedIn: "root"
})
export class LoggerService {
  constructor(@Inject(CORE_KIT_CONFIG_TOKEN) private config: CoreKitConfig) {}

  log(message: string, value: any = null): void {
    if (!this.config.isProduction) {
      if (value) {
        console.log(message, value);
      } else {
        console.log(message);
      }
    }
  }
}
