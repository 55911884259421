import { formatDate } from "@angular/common";
import { DateNavFilters, DatePickerRangeInfo, PeriodType, TimeFrameTypes } from "@app/models";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";

export class DateTimeZoneService {
  static getPostDto({ timeFrame, rangeInfo }: PeriodType): DateNavFilters {
    const timeZoneOffset = new Date().getTimezoneOffset();
    const postDto: DateNavFilters = { period: timeFrame, timeZoneOffset };

    if (timeFrame === TimeFrameTypes.Custom && rangeInfo?.fromDate && rangeInfo?.toDate) {
      const { startDate, endDate } = this.getNgbDateStringRangeDto(rangeInfo);

      return {
        ...postDto,
        startDate,
        endDate
      };
    }

    return postDto;
  }

  static getNgbDateStringRangeDto(rangeInfo: DatePickerRangeInfo): DateStringRangeDto {
    const dateRangeDto = this.getNgbDateRangeDto(rangeInfo);
    const res: DateStringRangeDto = {
      startDate: dateRangeDto.startDate ? dateRangeDto.startDate.toISOString() : null,
      endDate: dateRangeDto.endDate ? dateRangeDto.endDate.toISOString() : null
    };
    return res;
  }

  static getNgbDateRangeDto(rangeInfo: DatePickerRangeInfo): DateRangeDto {
    const res: DateRangeDto = {
      startDate: null,
      endDate: null
    };
    if (rangeInfo.fromDate) res.startDate = this.getDateFromNgb(rangeInfo.fromDate);
    if (rangeInfo.toDate) res.endDate = this.getDateFromNgb(rangeInfo.toDate, false);
    return res;
  }

  private static getDateFromNgb(ngbDate: NgbDate, start: boolean = true) {
    const date = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day, start ? 0 : 23, start ? 0 : 59, start ? 0 : 59);
    return date;
  }

  static getTimeZoneName(): string {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const getOffset = (tz) =>
      Intl.DateTimeFormat("en", {
        timeZoneName: "short",
        timeZone: tz
      })
        .formatToParts()
        .find((i) => i.type === "timeZoneName")
        .value // => "GMT+/-hh:mm"
        .slice(3); //=> +/-hh:mm

    const timeZone = getOffset(tz);
    return timeZone;
  }

  static getChartDateItem(date: string, currentTimeFrame: TimeFrameTypes, short: boolean = false): string {
    let format: string;
    if (currentTimeFrame === TimeFrameTypes.Day) {
      format = "ha";
    } else if (currentTimeFrame === TimeFrameTypes.Week) {
      format = "E";
    } else if (short) {
      format = "MM.dd";
    } else {
      format = "MMM dd";
    }
    const timeZoneName = this.getTimeZoneName();
    return formatDate(new Date(date), format, "en", timeZoneName);
  }

  static getFormatDateTimeZone(date) {
    const timeZoneName = this.getTimeZoneName();
    return formatDate(new Date(date), "yyyy-MM-ddThh:mm:ss", "en", timeZoneName);
  }

  static generateDefaultNgbDateRange(): DatePickerRangeInfo {
    const toDate = new Date();
    const fromDate = new Date(toDate.getFullYear(), toDate.getMonth(), 1, 1);
    const result = {
      fromDate: new NgbDate(fromDate.getFullYear(), fromDate.getMonth() + 1, 1),
      toDate: new NgbDate(toDate.getFullYear(), toDate.getMonth() + 1, toDate.getDate())
    };

    return result;
  }
}

export interface DateStringRangeDto {
  startDate: string | null;
  endDate: string | null;
}
export interface DateRangeDto {
  startDate: Date | null;
  endDate: Date | null;
}
