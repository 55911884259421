<ng-container *ngIf="isVisible">
  <!--ROWS = 2 -->
<!--  todo: check this case and implement auto width if it will be needed -->
  <div class="carousel small-rows2" *ngIf="rows == 2 && dataList?.length > itemsThresholdForRows">
    <div class="carousel__items-container">
      <ngx-simplebar [options]="scrollbarOptions">
        <div class="row">
          <div class="w250 text-center mb-4" *ngFor="let item of dataList; index as i">
            <app-cfs-template-preview-with-header
              class="carousel__item w250"
              [ngClass]="{ selected: selectedItemId && item.id === selectedItemId }"
              [screenSize]="screenSizeEnum.Full"
              [options]="item"
              (click)="clickSelectItem(item)">
            </app-cfs-template-preview-with-header>
          </div>
        </div>
      </ngx-simplebar>
    </div>

    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"> </span>
  </div>

  <!--ROWS = 1 -->
  <div
    class="carousel"
    *ngIf="!rows || dataList?.length <= itemsThresholdForRows"
    [class.empty]="!dataList?.length && !loading"
    [class.auto-width]="autoWidth">
    <div *ngIf="dataList?.length" class="carousel__items-container">
      <ngx-simplebar [options]="scrollbarOptions">
        <app-cfs-template-preview-with-header
          *ngFor="let item of dataList"
          class="carousel__item w250"
          [ngClass]="{ selected: selectedItemId && item.id === selectedItemId }"
          [screenSize]="screenSizeEnum.Full"
          [options]="item"
          (click)="clickSelectItem(item)">
        </app-cfs-template-preview-with-header>
      </ngx-simplebar>
    </div>

    <div *ngIf="!dataList?.length && !loading">
      <h5 class="text-truncate pb-1">
        {{ "common.error.notFound" | transloco }}
      </h5>
    </div>

    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"> </span>
  </div>
</ng-container>
