export type SmartPageCustomWidgetSettings = Partial<CustomWidgetSettingsBase>;

export interface CustomWidgetSettingsBase {
  title: string;
  subtitle: string;
  description: string;
  pwaIconUrl: string;
  primaryHeaderImageUrl: string;
  secondaryHeaderImageUrl: string;
  footerImageUrl: string;
  logoType: SmartPageLogoType;
  customLogoUrl: string;
}

export enum SmartPageLogoType {
  BrandLogo = 0,
  None = 1,
  CustomLogo = 2
}
