import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AnimationLoader, AnimationOptions, LottieComponent, provideLottieOptions } from "ngx-lottie";
import { BehaviorSubject } from "rxjs";
import {
  AssetParamKeys,
  CfsContentAsset,
  CfsQrCodeContentAsset,
  ContentSettingKeys,
  ContentSettings,
  getAllKeyValueAssetParams,
  getLottieColorByHex,
  getResponsiveAssetsInfo,
  capitalizeFirstLetter,
  parseQrCodeSettingValue,
  ScreenSizesJsonAsset,
  TemplatePreviewOptions,
  TemplatePreviewType
} from "clearline-common";
import { DigitalAssetGetDto, DigitalAssetScreenSize, KeyValueAsset, KeyValueDto } from "clearline-api";
import { TranslocoService } from "@ngneat/transloco";
import { AnimationItem } from "lottie-web";

type ResponsiveAssetsInfo = {
  [screenSize in DigitalAssetScreenSize]?: DigitalAssetGetDto | null;
};

type AnimationDataSizeAsset = {
  [screenSize in DigitalAssetScreenSize]?: any;
};

type ContentAssetByKeys = {
  [key in AssetParamKeys]: string;
};

const backgroundImageKey = "%%BACKGROUND_IMAGE%%";

@Component({
  selector: "lib-lottie-template",
  templateUrl: "./lottie-template.component.html",
  styleUrls: ["./lottie-template.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, LottieComponent],
  providers: [
    provideLottieOptions({
      player: () => import("lottie-web")
    }),
    AnimationLoader
  ]
})
export class LottieTemplateComponent implements AfterViewInit {
  @Input() isPrintView = false;
  @Input() previewType: TemplatePreviewType = TemplatePreviewType.Active;

  @Input() set screenSize(screenSize: DigitalAssetScreenSize | null) {
    if (this.templateScreenSize && this.templateScreenSize === screenSize) {
      return;
    }

    this.templateScreenSize = screenSize ?? DigitalAssetScreenSize.Full;

    if (this.animationDataSizeAsset[this.templateScreenSize]) {
      // set only if templateOptions is already set
      this.setAnimationToOptions();
    }
  }

  @Input() set options(options: TemplatePreviewOptions | null) {
    if (options) {
      this.setOptions(options);
    }
  }

  @Output() printReady = new EventEmitter();

  private readonly contentAssetByKeys: ContentAssetByKeys = {
    [AssetParamKeys.MainBackgroundColor]: ContentSettingKeys.BackgroundColor,
    [AssetParamKeys.ButtonBackgroundColor]: ContentSettingKeys.ButtonColor,
    [CfsContentAsset.MainBackgroundColorHex]: "",
    [CfsContentAsset.ButtonBackgroundColorHex]: "",
    [AssetParamKeys.Param1]: "",
    [AssetParamKeys.Param2]: ContentSettingKeys.CampaignSchedule,
    [AssetParamKeys.Param3]: ContentSettingKeys.TemplateCategory,
    [AssetParamKeys.Param4]: "",
    [AssetParamKeys.Param5]: "",
    [AssetParamKeys.Param6]: ""
  };

  private colorSettingKeys = [ContentSettingKeys.BackgroundColor, ContentSettingKeys.ButtonColor];
  private animationDataSizeAsset: AnimationDataSizeAsset = {
    [DigitalAssetScreenSize.Full]: null,
    [DigitalAssetScreenSize.Compact]: null,
    [DigitalAssetScreenSize.Vertical]: null
  };
  private backgroundColor?: string;
  private backgroundImage?: string;
  private qrCodeUrl?: string;
  private imageUrl?: string;

  templateScreenSize: DigitalAssetScreenSize = DigitalAssetScreenSize.Full;
  options$ = new BehaviorSubject<AnimationOptions>(null as any);

  constructor(private element: ElementRef, private translateService: TranslocoService) {}

  ngAfterViewInit(): void {
    this.setBackground();
  }

  animationCreated(animationItem: AnimationItem): void {
    if (this.isPrintView || this.previewType === TemplatePreviewType.Inactive) {
      animationItem.goToAndStop(0);
    }
  }

  domLoaded(): void {
    if (this.isPrintView) {
      const waitingList = [];

      if (this.imageUrl) {
        waitingList.push(fetch(this.imageUrl));
      }

      if (this.qrCodeUrl) {
        waitingList.push(fetch(this.qrCodeUrl));
      }

      Promise.all(waitingList).then(() => {
        this.printReady.emit();
      });
    }
  }

  private setOptions(options: TemplatePreviewOptions): void {
    const { contentSettings, defaultParameters, json, screenSizesJsonAsset } = options;
    this.backgroundColor = contentSettings?.backgroundColor || this.parseQrCodeBackgroundColor(defaultParameters);
    this.backgroundImage = this.getBackgroundImage(options);
    const assetsInfo: ResponsiveAssetsInfo = getResponsiveAssetsInfo(options);

    this.setBackground();

    this.animationDataSizeAsset = Object.keys(assetsInfo).reduce((acc: ResponsiveAssetsInfo, itemScreenSize, index: number) => {
      const assetItem: DigitalAssetGetDto | null | undefined = assetsInfo[itemScreenSize as DigitalAssetScreenSize];
      const itemDefaultParams: KeyValueDto[] = assetItem?.configuration?.defaultParameters || [];
      const itemJson: string = this.getItemJsonByScreenSize(screenSizesJsonAsset, itemScreenSize as DigitalAssetScreenSize, json);

      return {
        ...acc,
        [itemScreenSize]: this.getAnimationData(itemJson, itemDefaultParams as any, options)
      };
    }, {} as any);

    this.setAnimationToOptions();
  }

  private setBackground(): void {
    if (this.element.nativeElement) {
      const contentWrapperElement: HTMLElement = this.element.nativeElement;

      if (this.backgroundColor) {
        contentWrapperElement.style.backgroundColor = this.backgroundColor;
        contentWrapperElement.style.backgroundImage = "";
      } else if (this.backgroundImage) {
        contentWrapperElement.style.backgroundImage = this.backgroundImage;
        contentWrapperElement.style.backgroundColor = "";
      }

      if (this.isPrintView) {
        contentWrapperElement.style.backgroundColor = "#fff";
        contentWrapperElement.style.backgroundImage = "";
      }
    }
  }

  private getBackgroundImage(options: TemplatePreviewOptions): string {
    const params: KeyValueAsset = getAllKeyValueAssetParams(options, []);

    return Object.keys(params).reduce((acc: string, key: string) => {
      const value: string = params[key];

      if (key === backgroundImageKey) {
        acc = value;
      }

      return acc;
    }, "");
  }

  private setAnimationToOptions(): void {
    const animationData = this.animationDataSizeAsset[this.templateScreenSize || DigitalAssetScreenSize.Full];
    const lottieOptions: AnimationOptions = { loop: true, autoplay: true, animationData };

    this.options$.next(lottieOptions);
  }

  private getAnimationData(jsonText: string | undefined, digitalDefaultParams: KeyValueDto[], options: TemplatePreviewOptions): any {
    if (!jsonText) return null;

    const { contentSettings, mediaContent } = options || {};
    const isOldLottie: boolean = !!contentSettings?.displayContent || !!contentSettings?.templateCategory;

    if (mediaContent && !isOldLottie) {
      const allParams: KeyValueAsset = getAllKeyValueAssetParams(options, digitalDefaultParams);

      if (allParams[CfsQrCodeContentAsset.qrCodeUrl]) {
        this.qrCodeUrl = allParams[CfsQrCodeContentAsset.qrCodeUrl];
      }

      if (allParams[CfsQrCodeContentAsset.imageUrl]) {
        this.imageUrl = allParams[CfsQrCodeContentAsset.imageUrl];
      }

      Object.keys(allParams).forEach((key: string) => {
        jsonText = jsonText?.replace(key, allParams[key]);
      });

      return JSON.parse(jsonText);
    } else {
      return contentSettings
        ? this.getLottieJson(jsonText, digitalDefaultParams, contentSettings)
        : this.getLottieJsonByAssetParams(jsonText, digitalDefaultParams);
    }
  }

  private getLottieJson(text: string, params: KeyValueDto[] | undefined, settings: ContentSettings): any {
    Object.keys(this.contentAssetByKeys).forEach((key: string) => {
      let value: string = this.tryGetPlaceholder(key, settings);

      if (!value) {
        if (key === AssetParamKeys.Param1) {
          let displayContent: string = capitalizeFirstLetter(settings.displayContent);

          if (!displayContent) displayContent = capitalizeFirstLetter(settings.templateCategory);

          value = displayContent;
        } else {
          value = this.getJsonValueDependOnKey(key, params as KeyValueDto[], settings);
        }
      }

      text = text.replace(key, value.replace(/"/g, '\\"'));
    });

    return JSON.parse(text);
  }

  private getLottieJsonByAssetParams(jsonText: string, params: KeyValueDto[] = []): any {
    params?.forEach((item: KeyValueDto) => {
      jsonText = jsonText.replace(item.key, item.value);
    });

    return JSON.parse(jsonText);
  }

  private tryGetPlaceholder(key: string, settings: ContentSettings) {
    if (key === AssetParamKeys.Param1 && !settings.displayContent)
      return this.translateService.translateObject("templates.templateTopPlaceholder" + (settings.isPredefined ? "Select" : "Enter"));
    if (key === AssetParamKeys.Param3 && !settings.templateCategory)
      return this.translateService.translateObject("templates.templateButtonPlaceholder");
    if (key === AssetParamKeys.Param2 && !settings.campaignSchedule)
      return this.translateService.translateObject("templates.campaignSchedule" + (settings.isPredefined ? "Select" : "Enter"));
    return null;
  }

  private getJsonValueDependOnKey(key: string, params: KeyValueDto[], settings: ContentSettings): string {
    let value: string;
    const contentSettingKey = this.contentAssetByKeys[key as AssetParamKeys];
    const valueBySettings: string = contentSettingKey ? (settings as any)[contentSettingKey] : null;
    value = valueBySettings ? valueBySettings : (this.getContentDefaultValue(params, key) as string);

    if (valueBySettings && contentSettingKey === ContentSettingKeys.CampaignSchedule) {
      value = value.toUpperCase();
    } else if (valueBySettings && this.colorSettingKeys.some((colorKey: string) => colorKey === contentSettingKey)) {
      value = getLottieColorByHex(value);
    } else {
      value = capitalizeFirstLetter(value);
    }

    return value;
  }

  private getContentDefaultValue(assetParams: KeyValueDto[] | undefined, key: string): string {
    return assetParams?.find((item: KeyValueDto) => item.key === key)?.value || "";
  }

  private getItemJsonByScreenSize(
    screenSizesJsonAsset?: ScreenSizesJsonAsset,
    itemScreenSize?: DigitalAssetScreenSize,
    json?: string
  ): string {
    let result = "";

    if (screenSizesJsonAsset) {
      result = screenSizesJsonAsset[itemScreenSize as DigitalAssetScreenSize] || "";
    } else if (itemScreenSize === DigitalAssetScreenSize.Full) {
      result = json || "";
    }

    return result;
  }

  private parseQrCodeBackgroundColor(defaultParameters?: KeyValueAsset): string {
    if (defaultParameters && defaultParameters[CfsQrCodeContentAsset.backgroundColor]) {
      return parseQrCodeSettingValue(
        "backgroundColor",
        defaultParameters[CfsQrCodeContentAsset.backgroundColor]
      ) as string;
    }
    return "";
  }
}
