/// <reference types="youtube" preserve="true" />
import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, NgZone, OnDestroy, ViewChild } from "@angular/core";
import { ExternalLinkVideoPlayerComponent } from "../external-link-video-player";
import {
  AutoHide,
  AutoPlay,
  Controls,
  FullscreenButton,
  IvLoadPolicy,
  KeyboardControls,
  Loop,
  Mute,
  PlayerState,
  RelatedVideos
} from "./youtube-player";
import { mediaReadyDelay } from "../../cfs-template";
import { YoutubePlayerService } from "./youtube-player.service";
import { take } from "rxjs/operators";
import { TranslocoModule } from "@ngneat/transloco";
import { RelativeLoaderComponent } from "../../relative-loader";
import { provideClearlineCommonTranslocoScope } from "../../../utils";
import { BehaviorSubject } from "rxjs";

interface ThumbnailImageInfo {
  url?: string;
  loaded?: boolean;
}

export const youtubeUrlPattern =
  /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})(?:[?&]\S*)?$/;

@Component({
  selector: "lib-youtube-player",
  templateUrl: "./youtube-player.component.html",
  styleUrls: ["./youtube-player.component.scss"],
  standalone: true,
  imports: [CommonModule, TranslocoModule, RelativeLoaderComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideClearlineCommonTranslocoScope()]
})
export class YoutubePlayerComponent extends ExternalLinkVideoPlayerComponent implements OnDestroy {
  @ViewChild("player", { static: true }) playerElement: ElementRef | undefined;

  protected readonly PlayerState = PlayerState;
  protected readonly urlPattern = youtubeUrlPattern;

  protected override player: YT.Player | null = null;
  protected videoId = "";
  protected playerState: PlayerState = PlayerState.UNSTARTED;
  protected playerStateKey$ = new BehaviorSubject("UNSTARTED");
  protected thumbnailImageInfo$ = new BehaviorSubject<ThumbnailImageInfo>({ url: "", loaded: false });
  protected displayThumbnailOverlay$ = new BehaviorSubject(false);
  protected displayUploadPlaceholder$ = new BehaviorSubject(true);

  constructor(private service: YoutubePlayerService, private zone: NgZone, private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.updateOverlays();
  }

  async ngOnDestroy() {
    this.isDestroyInProgress = true;

    await this.resetPlayer();
  }

  play(): void {
    if (this.isPlayerActive() && this.player?.playVideo) {
      this.player?.playVideo();
    }
  }

  pause(): void {
    if (this.isPlayerActive() && this.player?.pauseVideo) {
      this.player?.pauseVideo();
    }
  }

  stop(): void {
    if (this.isPlayerActive() && this.player?.stopVideo) {
      this.player?.stopVideo();
    }
  }

  getVideoDuration(): Promise<number> {
    if (!this.isPlayerActive() || !this.player?.getDuration) {
      return Promise.resolve(0);
    }

    return Promise.resolve(this.player?.getDuration() || 0);
  }

  getThumbnailUrl(): Promise<string> {
    const thumbnailUrl = this.getThumbnailUrlById(this.videoId);

    return Promise.resolve(thumbnailUrl);
  }

  protected loadPlayer(): void {
    if (this.videoId) {
      this.service.onApiReady$.pipe(take(1)).subscribe(() => this.createPlayer());
    } else {
      void this.resetPlayer();
    }
  }

  protected onThumbnailImageLoad(): void {
    this.thumbnailImageInfo$.next({ ...this.thumbnailImageInfo$.value, loaded: true });
  }

  protected getVideoIdByUrl(url: string): string {
    const match = url.match(this.urlPattern) || [];

    return match.length ? match[1] : "";
  }

  private createPlayer(): void {
    const { muted = true, loop = false } = this.mediaConfig || {};

    this.clearSettings();
    this.player?.destroy();
    this.updateOverlays();

    this.zone.runOutsideAngular(() => {
      this.player = new YT.Player(this.playerElement?.nativeElement, {
        videoId: this.videoId,
        playerVars: {
          autohide: AutoHide.HideAllControls,
          autoplay: AutoPlay.NoAutoPlay,
          controls: Controls.Hide,
          disablekb: KeyboardControls.Disable,
          fs: FullscreenButton.Hide,
          iv_load_policy: IvLoadPolicy.Hide,
          loop: loop ? Loop.Loop : Loop.SinglePlay,
          mute: muted ? Mute.Muted : Mute.NotMuted,
          rel: RelatedVideos.Hide,
          playlist: this.videoId
        },
        events: {
          onError: this.onPlayerError.bind(this),
          onReady: this.onPlayerReady.bind(this),
          onStateChange: this.onPlayerStateChange.bind(this)
        }
      });
      this.loaded = true;

      this.thumbnailImageInfo$.next({ url: this.getThumbnailUrlById(this.videoId), loaded: false });
      this.updateOverlays();
    });
  }

  private async resetPlayer(): Promise<void> {
    console.log("Vimeo player reset", !!this.player); // todo: remove logs after testing
    if (this.player) {
      await this.player.destroy();
      this.player = null;
    }

    return Promise.resolve();
  }

  private onPlayerReady(event: YT.PlayerEvent): void {
    console.log("Youtube player ready", event); // todo: remove logs after testing
    const iframeElement: HTMLIFrameElement | null = this.player?.getIframe() || null;

    if (iframeElement) {
      iframeElement.style.pointerEvents = "none";
    }

    setTimeout(() => {
      this.ready.emit();

      if (!!this.mediaConfig.autoplay) {
        this.play();
      }
    }, mediaReadyDelay);
  }

  private onPlayerStateChange(event: YT.OnStateChangeEvent): void {
    this.setPlayerState(event.data);
  }

  private onPlayerError(): void {
    this.setError();
    this.clearSettings();
    this.updateOverlays();
  }

  private clearSettings(): void {
    this.setPlayerState(PlayerState.UNSTARTED);
    this.thumbnailImageInfo$.next({ url: "", loaded: false });
  }

  private setPlayerState(playerState: PlayerState = PlayerState.UNSTARTED): void {
    this.playerState = playerState;
    const playerStateKey = Object.keys(PlayerState).find((key) => PlayerState[key as keyof typeof PlayerState] === this.playerState);

    this.playerStateKey$.next(playerStateKey as string);
    console.log("onPlayerStateChange", playerStateKey); // todo: remove later
    this.updateOverlays();
  }

  private updateOverlays(): void {
    this.updateDisplayThumbnailOverlay();
    this.updateDisplayUploadPlaceholder();
    this.changeDetectorRef.detectChanges();
  }

  private updateDisplayThumbnailOverlay(): void {
    const displayThumbnailOverlay: boolean =
      !!this.videoId && !!this.player && this.loaded && this.playerState !== PlayerState.PLAYING && !this.playerErrorOccurred;

    this.displayThumbnailOverlay$.next(displayThumbnailOverlay);
  }

  private updateDisplayUploadPlaceholder(): void {
    const displayUploadPlaceholder: boolean =
      this.displayUploadPlaceholder &&
      (!this.videoId || !this.loaded || (!this.thumbnailImageInfo$.value.loaded && this.displayThumbnailOverlay$.value));

    this.displayUploadPlaceholder$.next(displayUploadPlaceholder);
  }

  private getThumbnailUrlById(videoId = ""): string {
    return videoId && this.player ? `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg` : "";
  }
}
