import { Injectable } from "@angular/core";
import { AccountSubscription } from "@app/models";
import { CompanyService } from "clearline-api";
import { Observable } from "rxjs";
import { DataService } from "../core/services/data.service";

@Injectable({
  providedIn: "root"
})
export class CompanyDataService extends CompanyService {
  constructor(private http: DataService) {
    super(http);
  }

  getAccountSubscription(): Observable<AccountSubscription> {
    return this.http.get(`${this.serviceUrl}/accountSubscription`);
  }
}
