import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CfsTemplatePreviewComponent, TemplatePreviewOptions, TemplatePreviewType } from "clearline-common";
import { LottieComponent } from "ngx-lottie";
import { DigitalAssetScreenSize } from "clearline-api";
import { HtmlTemplateComponent } from "clearline-common";

@Component({
  selector: "app-cfs-template-preview-with-header",
  standalone: true,
  imports: [CommonModule, CfsTemplatePreviewComponent, HtmlTemplateComponent, LottieComponent],
  templateUrl: "./cfs-template-preview-with-header.component.html",
  styleUrl: "./cfs-template-preview-with-header.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CfsTemplatePreviewWithHeaderComponent {
  @Input() screenSize: DigitalAssetScreenSize = DigitalAssetScreenSize.Full;
  @Input() options: TemplatePreviewOptions;

  readonly TemplatePreviewType = TemplatePreviewType;
}
