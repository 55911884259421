<ng-container *transloco="let t; read: i18nScope">
  <div *ngIf="displayUploadPlaceholder$ | async" class="upload-placeholder">
    <span *ngIf="!videoId">{{  t('media.video.uploadPlaceholder') }}</span>

    <lib-relative-loader *ngIf="videoId"></lib-relative-loader>
  </div>
</ng-container>

<!-- Transparent overlay to intercept inner iframe clicks -->
<!-- Thumbnail overlay to hide Youtube video not playing state view. It is black until thumbnail image loads -->
<div
  class="overlay"
  [class.thumbnail-overlay]="(displayThumbnailOverlay$ | async)"
  [class.with-upload-placeholder]="displayUploadPlaceholder">
  <img
    *ngIf="(thumbnailImageInfo$ | async) as thumbnailImageInfo"
    alt=""
    [src]="thumbnailImageInfo.url"
    (load)="onThumbnailImageLoad()">
</div>

<div #player></div>
