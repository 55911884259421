import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { AccountService, AuthService } from "@app/core";
import { Command } from "@app/core/commands/command";
import { Brand, ConditionWithLocationIdsDto, CustomizedCampaign, Location, LocationWidgetSettings } from "@app/models";
import { AppDemoModalComponent } from "@app/pages/components/widget-settings/modals/app-demo-modal/app-demo-modal.component";
import { AppPreviewModalComponent } from "@app/pages/components/widget-settings/modals/app-preview-modal/app-preview-modal.component";
import { SmartPageWidgetSettings } from "@app/pages/components/widget-settings/smart-page-widget-settings/smart-page-widget-settings";
import {
  WidgetSettings,
  WidgetSettingsModalComponent
} from "@app/pages/components/widget-settings/widget-settings-modal/widget-settings-modal.component";
import { ConditionService, LandingService, WidgetService } from "@app/services";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslocoService } from "@ngneat/transloco";
import { isEqualFlatObjects, LocationWidgetSettingsMode, WidgetType } from "clearline-common";
import { RoleTypes } from "core-kit";
import { Subject } from "rxjs";
import { take } from "rxjs/operators";

@Component({
  selector: "app-widgets-form-control",
  templateUrl: "./widgets-form-control.component.html",
  styleUrls: ["./widgets-form-control.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetsFormControlComponent implements OnInit, OnDestroy {
  constructor(
    private widgetSvc: WidgetService,
    private modalSvc: NgbModal,
    private landingSvc: LandingService,
    private cdr: ChangeDetectorRef,
    private translateSvc: TranslocoService,
    private accountSvc: AccountService,
    private authService: AuthService,
    private conditionService: ConditionService
  ) {}

  private locationWidgets: LocationWidgetSettings[] = [];
  private _widgets: Array<CustomizedCampaign>;
  public commands: Array<Command<CustomizedCampaign>>;
  private unsubscribe$ = new Subject<void>();

  @Input() public showHeader = true;
  @Input() location: Location;
  @Input() brand: Brand;
  @Input() public isQuickSetup = false;
  @Input() public selectedWidgetId: number;

  @Input() widgetsInThePlan: Array<CustomizedCampaign>;
  @Input() locationWidgetSettingsList: LocationWidgetSettings[];
  @Input() locationWidgetMode: LocationWidgetSettingsMode = LocationWidgetSettingsMode.Cmc;

  @Input() public set widgets(widgets: Array<CustomizedCampaign>) {
    if (widgets && !isEqualFlatObjects(widgets, this._widgets)) {
      this._widgets = widgets;

      setTimeout(() => {
        this.locationWidgets = widgets.map((widget: CustomizedCampaign) => {
          const cachedWidget: LocationWidgetSettings = this.locationWidgetSettingsList?.find(
            (lw: LocationWidgetSettings) => lw?.defaultWidgetSettingsId === widget.id
          );
          widget.locationWidgetSettings = cachedWidget || this.generateLocationWidgetSettings(widget);

          return widget.locationWidgetSettings;
        });

        this.updated.emit(this.locationWidgets);
        if (this.selectedWidgetId) {
          const widget = this._widgets.find((w) => w.id == this.selectedWidgetId);
          if (widget && !widget.locationWidgetSettings.disabled) this.openSettings(widget);
        }
        this.cdr.detectChanges();
      });

      this.landingSvc.getPublishedLandingPages().subscribe((pages) => {
        pages.forEach((p) => {
          const url = this.landingSvc.getProdLandingPageUrl(p.id, this.brand.shortName, this.location?.id);
          const widget = this.widgets.find((w) => w.id === p.purpose);
          if (widget) widget.locationWidgetSettings.landingUrl = url;
        });

        this.cdr.detectChanges();
      });
    }
  }

  @Output() updated = new EventEmitter<LocationWidgetSettings[]>();
  @Output() updatedOneWidget = new EventEmitter<LocationWidgetSettings>();

  public get widgets(): Array<CustomizedCampaign> {
    return this._widgets;
  }

  get isAdmin() {
    return this.authService.requredMinimumRole(RoleTypes.PartnerAdmin);
  }

  ngOnInit(): void {
    const self = this;

    this.commands = [
      new Command<CustomizedCampaign>(
        "Setup",
        this.isQuickSetup
          ? this.translateSvc.translateObject("widgets.common.setup")
          : this.translateSvc.translateObject("widgets.common.edit"),
        "mdi mdi-cog",
        "success",
        (campaign: CustomizedCampaign) => self.canExecute(campaign),
        (campaign: CustomizedCampaign) => self.openSettings(campaign),
        this.translateSvc.translateObject("widgets.common.open")
      ),
      this.isQuickSetup
        ? new Command<CustomizedCampaign>(
            "Demo",
            this.translateSvc.translateObject("widgets.common.demo"),
            "mdi mdi-eye",
            "primary",
            (campaign: CustomizedCampaign) => self.canExecuteDemo(campaign),
            (campaign: CustomizedCampaign) => self.viewDemoSettings(campaign)
          )
        : new Command<CustomizedCampaign>(
            "View",
            this.translateSvc.translateObject("widgets.common.view"),
            "mdi mdi-eye",
            "primary",
            (campaign: CustomizedCampaign) => self.canView(campaign),
            (campaign: CustomizedCampaign) => self.viewAppSettings(campaign),
            this.translateSvc.translateObject("widgets.common.viewApp")
          )
    ];
  }

  openSettings(widget: CustomizedCampaign) {
    const largeModalWidgets = [WidgetType.IssueCoupon, WidgetType.SmartPage];
    const classSize = largeModalWidgets.includes(widget.id) ? "xxl" : "lg";
    const featureModal = this.modalSvc.open(WidgetSettingsModalComponent, { backdrop: "static", size: classSize, centered: true });
    const comp: WidgetSettingsModalComponent = featureModal.componentInstance;
    comp.title = widget.name;
    comp.widgetId = widget.id;
    comp.locationWidget = widget?.locationWidgetSettings;
    comp.widgetSettingsJson = widget?.locationWidgetSettings?.widgetSettingsJson;
    comp.deliveryMethod = widget?.locationWidgetSettings?.deliveryMethod;
    comp.configured = widget?.locationWidgetSettings?.configured;
    comp.location = this.location;
    comp.brand = this.brand;
    comp.createMode = this.isQuickSetup;
    comp.widgetsInThePlan = this.widgetsInThePlan;
    const hash = window.location.hash;
    window.location.hash = hash + "-" + widget.name.toLowerCase().replace(/ /g, "-").replace("-/", "");

    featureModal.result
      .then((wSettings: WidgetSettings) => {
        window.location.hash = hash;
        const locWg = this.locationWidgets.find((lw) => lw.defaultWidgetSettingsId === widget.id);
        locWg.widgetSettingsJson = wSettings.widgetSettingsJson;
        locWg.deliveryMethod = wSettings.deliveryMethod;
        locWg.configured = wSettings.configured;
        this.handleWidgetChangeOnResult(locWg);
      })
      .catch((res) => {
        window.location.hash = hash;
      });
  }

  onWidgetChange(widget: LocationWidgetSettings) {
    if (widget.disabled) {
      this.handleDisabledWidget(widget);
    } else {
      this.handleWidgetChangeOnResult(widget);
    }
  }

  isInteractiveWidget(widget: CustomizedCampaign): boolean {
    return widget.id !== WidgetType.IssuePointsOrStamps;
  }

  private handleDisabledWidget(widget: LocationWidgetSettings): void {
    this.updateSmartWidgetApps(widget);
    this.conditionService
      .getWidgetConditions(widget.defaultWidgetSettingsId)
      .pipe(take(1))
      .subscribe((info: ConditionWithLocationIdsDto[] = []) => {
        const hasConditionOnLocation: boolean = info?.some((item: ConditionWithLocationIdsDto) => {
          return item.locationIds.some((locationId: number) => locationId === this.location.id);
        });

        if (hasConditionOnLocation || this.getDeactiveSMSCampaignByWidgets(widget)) {
          const confirmMessage: string = this.translateSvc.translateObject("widgets.common.disableConfirmMessage");
          const skipConfirmation = this.locationWidgetMode === LocationWidgetSettingsMode.Cfs;

          if (!skipConfirmation) {
            widget.disabled = confirm(confirmMessage);
          }

          if (widget.disabled) {
            this.handleWidgetChangeOnResult(widget);
          }
        } else {
          this.handleWidgetChangeOnResult(widget);
        }
      });
  }

  getDeactiveSMSCampaignByWidgets(widget: LocationWidgetSettings) {
    return this.widgetSvc.getDeactiveSMSCampaignSettings(widget.defaultWidgetSettingsId, widget);
  }

  updateSmartWidgetApps(widget: LocationWidgetSettings) {
    if (!widget.disabled || widget.defaultWidgetSettingsId === WidgetType.SmartPage) return;
    const smartWidget = this.widgets.find((w) => w.id === WidgetType.SmartPage);

    if (smartWidget?.locationWidgetSettings && smartWidget.locationWidgetSettings.widgetSettingsJson) {
      const settings: SmartPageWidgetSettings = JSON.parse(smartWidget.locationWidgetSettings.widgetSettingsJson);

      if (settings?.marketingActions?.length) {
        settings.marketingActions = settings.marketingActions.map((app) => {
          if (app.widgetId === widget.defaultWidgetSettingsId) {
            app.enabled = false;
          }
          return app;
        });

        smartWidget.locationWidgetSettings.widgetSettingsJson = JSON.stringify(settings);
      }
    }
  }

  private generateLocationWidgetSettings(widget: CustomizedCampaign): LocationWidgetSettings {
    const lwSettings = new LocationWidgetSettings();
    lwSettings.defaultWidgetSettingsId = widget.id;
    lwSettings.disabled = widget.disabled;

    return lwSettings;
  }

  private handleWidgetChangeOnResult(widget: LocationWidgetSettings): void {
    this.updated.emit(this.locationWidgets);
    this.updatedOneWidget.emit(widget);
  }

  public widgetIcon(id: number): string {
    return this.widgetSvc.getWidgetIcon(id);
  }

  public widgetHasSettings(widget: LocationWidgetSettings) {
    return widget.widgetSettingsJson === null;
  }

  public getWarningMsg(widget: CustomizedCampaign) {
    if (!widget?.locationWidgetSettings?.widgetSettingsJson && this.widgetSvc.hasWidgetCampaign(widget.id)) return "";
    if (!widget.locationWidgetSettings.configured && !this.widgetHasSettings(widget.locationWidgetSettings) && !this.isQuickSetup)
      return `${widget.name}${
        this.isAdmin
          ? this.translateSvc.translateObject("widgets.common.pendingConfigAdmin")
          : this.translateSvc.translateObject("widgets.common.pendingConfig")
      }`;
  }

  private canExecute(widget: CustomizedCampaign) {
    return this.widgetSvc.hasWidgetCampaign(widget.id);
  }

  private canView(widget: CustomizedCampaign): boolean {
    return (
      this.widgetSvc.hasWidgetCampaign(widget.id) &&
      !this.widgetHasSettings(widget.locationWidgetSettings) &&
      !!this.widgetSvc.getQrSettings(widget.id, widget?.locationWidgetSettings)?.url
    );
  }

  private canExecuteDemo(widget: CustomizedCampaign): boolean {
    return (widget.description !== null && widget.description !== "") || (widget.videoId !== null && widget.videoId !== "");
  }

  private viewAppSettings(widget: CustomizedCampaign) {
    const featureModal = this.modalSvc.open(AppPreviewModalComponent, { backdrop: "static", size: "lg", centered: true });
    const comp: AppPreviewModalComponent = featureModal.componentInstance;
    comp.title = widget.name;
    comp.widgetId = widget.id;
    comp.locationId = this.location.id;
    comp.settings = this.widgetSvc.getQrSettings(widget.id, widget?.locationWidgetSettings);
  }

  private viewDemoSettings(widget: CustomizedCampaign) {
    const featureModal = this.modalSvc.open(AppDemoModalComponent, { backdrop: "static", size: "lg", centered: true });
    const comp: AppDemoModalComponent = featureModal.componentInstance;
    comp.title = widget.name;
    comp.description = widget.description;
    comp.videoId = widget.videoId;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
