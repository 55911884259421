<div class="template-preview non-fullscreen flex-column">
  <div *ngIf="options?.previewIcon as icon" class="content-icon">
    <i class="{{ icon }}"></i>
  </div>

  <div class="title-container content-title item-title">
    <ng-container *ngIf="options?.previewTitle as title; else defaultTitle">
      <span class="template-title">{{ title }}</span>
    </ng-container>

    <ng-template #defaultTitle>
      <ng-container *ngIf="options?.digitalAsset as asset">
        <span class="template-title">{{ asset.title }}</span>
      </ng-container>

      <ng-container *ngIf="options?.mediaContent as mediaContent">
        <span class="template-title">{{ mediaContent.title }}</span>
      </ng-container>
    </ng-template>
  </div>

  <div class="template-preview__content-wrapper">
    <lib-cfs-template-preview
      class="template-preview__content"
      [fullScreen]="false"
      [previewType]="TemplatePreviewType.Inactive"
      [screenSize]="screenSize"
      [options]="options">
    </lib-cfs-template-preview>
  </div>
</div>
