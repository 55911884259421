import { AfterViewInit, Directive, ElementRef, HostListener, Input, Renderer2 } from "@angular/core";
import { DigitalAssetScreenSize } from "clearline-api";
import { getDigitalAssetScreenSize } from "clearline-common";

const referenceHeightScreensAsset = {
  Full: 1080,
  Vertical: 1920,
  Tablet: 1024,
  Compact: 812
};
const aspectRatioScreensAsset = {
  Full: 16 / 9,
  Vertical: 9 / 16,
  Tablet: 3 / 4,
  Compact: 375 / 812
};

@Directive({
  selector: "[libHtmlTemplate]",
  standalone: true
})
export class HtmlTemplateDirective implements AfterViewInit {
  @Input() responsive = false;
  @Input() isLowResolutionScreen = false;
  @Input() isPrintView = false;
  @Input() printBackgroundColor = "white";

  private readonly a4PortraitWidth = 297;
  private readonly a4PortraitHeight = 210;

  private currentScreenSize: DigitalAssetScreenSize | null = null;

  @Input() set globalScreenSize(screenSize: DigitalAssetScreenSize | null) {
    this.applyScreenSize(screenSize || DigitalAssetScreenSize.Full);
    this.applyStyles();
  }

  @HostListener("window:resize") onResize() {
    if (this.responsive) {
      this.updateScreenSize();
    }
    this.applyStyles();
  }

  constructor(private element: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    if (this.responsive) {
      this.updateScreenSize();
    }
    this.applyStyles();
  }

  private applyScreenSize(screenSize: DigitalAssetScreenSize) {
    if (this.currentScreenSize !== screenSize) {
      if (this.currentScreenSize) {
        this.renderer.removeClass(this.element.nativeElement, this.currentScreenSize);
      }

      this.currentScreenSize = screenSize;
      this.renderer.addClass(this.element.nativeElement, this.currentScreenSize);
    }
  }

  private updateScreenSize() {
    const currentScreenSize: DigitalAssetScreenSize = getDigitalAssetScreenSize(this.isLowResolutionScreen, true);

    this.applyScreenSize(currentScreenSize);
  }

  private applyStyles() {
    if (this.element.nativeElement.clientWidth) {
      if (this.isPrintView) {
        const parentElement = this.element.nativeElement.parentElement;

        if (parentElement) {
          this.renderer.setStyle(parentElement, "background", this.printBackgroundColor);
        }

        this.renderer.setStyle(this.element.nativeElement, "width", `${this.a4PortraitWidth}mm`);
        this.renderer.setStyle(this.element.nativeElement, "height", `${this.a4PortraitHeight}mm`);
      }

      const width = this.element.nativeElement.clientWidth;
      const height = this.element.nativeElement.clientHeight;
      const currentAspectRatio: number = this.getCurrentScreenSizeAspectRatio();
      const currentReferenceHeight: number = this.getCurrentScreenSizeReferenceHeight();
      const isAspectRatioBigger: boolean = width / height > currentAspectRatio;
      const contentHeight = isAspectRatioBigger && this.responsive ? height : width / currentAspectRatio;
      const scale: number = contentHeight / currentReferenceHeight;

      if (this.responsive) {
        const contentWidth = isAspectRatioBigger ? height * currentAspectRatio : width;

        this.renderer.setStyle(this.element.nativeElement, "--width", `${contentWidth}px`, 2);
      } else {
        this.renderer.setStyle(this.element.nativeElement, "--width", `100%`, 2);
      }

      this.renderer.setStyle(this.element.nativeElement, "--height", `${contentHeight}px`, 2);
      this.renderer.setStyle(this.element.nativeElement, "--scale", scale, 2);
    }
  }

  private getCurrentScreenSizeReferenceHeight(): number {
    return referenceHeightScreensAsset[this.currentScreenSize || DigitalAssetScreenSize.Full];
  }

  private getCurrentScreenSizeAspectRatio(): number {
    return aspectRatioScreensAsset[this.currentScreenSize || DigitalAssetScreenSize.Full];
  }
}
