import { Component, EventEmitter, HostBinding, Input, Output } from "@angular/core";
import { CfsTemplateMediaConfig, defaultCfsTemplateMediaConfig, VideoPlayer } from "clearline-common";
import { i18nScope } from "../../utils";

@Component({ template: ``, standalone: true })
export abstract class ExternalLinkVideoPlayerComponent implements VideoPlayer {
  @Input() mediaConfig: CfsTemplateMediaConfig = defaultCfsTemplateMediaConfig;
  @Input() displayUploadPlaceholder = false;
  @Input() index = 0;
  @Input() set url(url: string) {
    this.videoUrl = url;

    if (url) {
      this.applyUrl(url);
    }
  }

  @HostBinding("class.full-screen") get isFullScreen() {
    return !!this.mediaConfig.fullscreen;
  }

  @HostBinding("class.cover-parent") get isCoverParent() {
    return !!this.mediaConfig.coverParent;
  }

  @Output() ready = new EventEmitter();
  @Output() error = new EventEmitter();

  protected abstract urlPattern: RegExp;
  protected abstract videoId: string | number;

  protected readonly i18nScope = i18nScope;

  protected player: any;
  protected isDestroyInProgress = false;
  protected videoUrl = "";
  protected loaded = false;
  protected playerErrorOccurred = false;
  protected urlChecked = false;

  // Public methods
  abstract play(): void;

  abstract pause(): void;

  abstract stop(): void;

  abstract getVideoDuration(): Promise<number>;

  abstract getThumbnailUrl(): Promise<string>;

  hasError(): boolean {
    return this.playerErrorOccurred;
  }

  // Protected methods
  protected abstract getVideoIdByUrl(url: string): string | number;

  protected abstract loadPlayer(autoplay?: boolean): void;

  protected applyUrl(url: string): void {
    this.playerErrorOccurred = false;
    this.urlChecked = false;
    this.loaded = false;
    this.videoId = this.getVideoIdByUrl(url);

    this.loadPlayer();
  }

  protected setError(): void {
    this.playerErrorOccurred = true;

    this.error.emit();
    console.log("Error loading video player"); // todo: remove after testing
  }

  protected isPlayerActive(): boolean {
    return !!this.player && !this.isDestroyInProgress;
  }
}
