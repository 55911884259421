<div
  class="plan-card"
  *transloco="let t; read: 'quickSetup'"
  [class.card-selected]="plan.id === selectedPlanId"
  (click)="onSelectPlan(plan)">
  <div class="card w-100 h-100 card-radio card-radio-label pb-0 m-0">
    <div class="card-body pb-4">
      <div class="text-left">
        <h3 class="font-size-24 font-bold">{{ plan.name }}</h3>

        <h2 class="font-bold-700 pb-3">
          <small>$</small>
          {{ plan.price.toFixed(2) }}<span class="font-size-16 align-middle">/ {{ t("perMo") }}</span>
        </h2>

        <h4 *ngIf="plan.limits.isUnlimited" class="mb-4">
          <small>$</small>
          {{ t('oneTimeFee', { value: oneTimeFee }) }}
        </h4>
      </div>

      <div class="media">
        <ul *ngIf="plan.limits.isUnlimited" class="card-list">
          <li>{{ t('locationPerAccount', { count: locationPerAccount }) }}</li>
          <li>{{ t("unlimitedMerchantTablet") }}</li>
          <li>{{ plan.productId.includes("starter") ? t("unlimitedNoFS") : t("unlimitedFS") }}</li>
          <li>{{ t("unlimitedUsers") }}</li>
          <li>{{ plan.widgets?.length }} {{ t("unlimitedApp") }}</li>
          <li>{{ t("unlimitedQR") }}</li>
          <li>{{ t("unlimitedSMS&Email") }}</li>
          <li>{{ t('requiresContract') }}</li>
        </ul>

        <ul *ngIf="!plan.limits.isUnlimited" class="card-list">
          <li>{{ t('locationPerAccount', { count: locationPerAccount }) }}</li>
          <li>{{ t("unlimitedUsers") }}</li>
          <li>{{ plan.widgets?.length }} {{ t("unlimitedApp") }}</li>
          <li>{{ plan.limits.periodLimit }} {{ t("interMo") }}</li>
          <li>{{ t("freeTrial") }}</li>
        </ul>
      </div>
    </div>
  </div>
</div>
