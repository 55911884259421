import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { SubscriptionPlan } from "@app/models";
import { TranslocoModule } from "@ngneat/transloco";

@Component({
  selector: "app-pricing-plan-card",
  templateUrl: "./pricing-plan-card.component.html",
  styleUrls: ["./pricing-plan-card.component.scss"],
  standalone: true,
  imports: [NgIf, TranslocoModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PricingPlanCardComponent {
  @Input() plan: SubscriptionPlan;
  @Input() selectedPlanId: number;

  @Output() selectPlan = new EventEmitter<SubscriptionPlan>();

  readonly oneTimeFee = 95;
  readonly locationPerAccount = 1;

  onSelectPlan(plan: SubscriptionPlan): void {
    this.selectPlan.emit(plan);
  }
}
