<div
  *ngIf="options$ | async as options"
  class="html-template"
  [class.template-responsive]="responsive"
  [class.animated]="previewType === CFSPreviewType.Active"
  [style.background]="isPrintView ? this.printBackgroundColor : options.backgroundColor"
  [style.backgroundImage]="isPrintView ? '' : options.backgroundImage"
  libHtmlTemplate
  [globalScreenSize]="screenSize"
  [responsive]="responsive"
  [isPrintView]="isPrintView"
  [printBackgroundColor]="printBackgroundColor">
  <div class="template-wrapper">
    <div class="template-header">
      <img
        alt=""
        [src]="options.logoUrl"
        class="template-logo"
        (load)="onLogoLoaded()"
        (error)="onLogoError()">
    </div>

    <div class="template-content">
      <div class="template-column column-main">
        <h1 class="template-title" [style.color]="options.titleColor + '!important'">
          {{ options.titleText }}
        </h1>

        <span class="template-desc" [style.color]="options.subtitleColor">
          {{ options.subtitleText }}
        </span>

        <img
          alt=""
          [src]="options.qrCodeUrl"
          class="template-qr-code"
          (load)="onQrCodeLoaded()"
          (error)="onQrCodeError()">

        <span class="template-action" [style.color]="options.qrActionColor">
          {{ options.qrActionText }}
        </span>
      </div>

      <div class="template-column column-media">
        <button
          *ngIf="options.buttonWidth && options.buttonText"
          class="template-img-action"
          [style.background]="options.buttonBackgroundColor">
          <span [style.color]="options.buttonTextColor">
            {{ options.buttonText }}
          </span>
        </button>

        <img
          alt=""
          [src]="options.imageUrl"
          class="template-main-img"
          (load)="onMainImageLoaded()"
          (error)="onMainImageError()">
      </div>
    </div>
  </div>
</div>
