import { Component, Input, ViewChildren } from "@angular/core";
import { BrandColorPalette, CfsTemplate, TemplatesCarouselData } from "@app/models";
import { BehaviorSubject, combineLatest, timer } from "rxjs";
import { filter, map, switchMap, tap } from "rxjs/operators";
import { CfsTemplateComponent, getContentDurationByTemplate, getDisplayTimeByTemplate, TemplatePreviewType } from "clearline-common";

@Component({
  selector: "app-templates-carousel",
  templateUrl: "./templates-carousel.component.html",
  styleUrls: ["./templates-carousel.component.scss"]
})
export class TemplatesCarouselComponent {
  @ViewChildren(CfsTemplateComponent) templateComponents: CfsTemplateComponent[] = [];

  private readonly defaultInterval = 7000;
  private readonly delayBeforeUpdate = 30;

  private dataToUpdate: TemplatesCarouselData | null = null;

  readonly TemplatePreviewType = TemplatePreviewType;
  brandColorsPalette: BrandColorPalette | undefined;
  nextSlideIndex = 0;
  carouselEmpty = false;

  templates$ = new BehaviorSubject<CfsTemplate[]>([]);
  private nextSlideInterval$ = new BehaviorSubject<number>(0);

  private timer$ = this.nextSlideInterval$.pipe(switchMap((timeout) => timer(timeout)));

  ready$ = combineLatest([this.templates$, this.timer$]).pipe(
    map(([list]) => !!list.length || this.carouselEmpty),
    filter((ready) => ready),
    tap(() => {
      this.prepareNextSlide(this.templates$.value);
    })
  );

  @Input() set carouselData(data: TemplatesCarouselData | null | undefined) {
    if (data) {
      const { updateOnNewCircle } = data;

      if (this.templates$.value.length && updateOnNewCircle) {
        this.dataToUpdate = data;
      } else {
        this.generateSlidesListInfo(data);
      }
    }
  }

  templateReady(active: boolean, templateComponent: CfsTemplateComponent): void {
    if (active && templateComponent) {
      templateComponent.play();
    }
  }

  private getNextSlideIndex(list: CfsTemplate[]): number {
    return this.nextSlideIndex + 1 < list.length ? this.nextSlideIndex + 1 : 0;
  }

  private controlTemplates(): void {
    if (this.templateComponents?.length) {
      this.templateComponents.forEach((templateComponent, index) => {
        if (index === this.nextSlideIndex) {
          templateComponent.play();
        } else {
          templateComponent.stop();
        }
      });
    }
  }

  private prepareNextSlide(list: CfsTemplate[]): void {
    this.nextSlideIndex = this.getNextSlideIndex(list);

    const isLastSlide = this.nextSlideIndex === list?.length - 1;
    const nextSlide = list[this.nextSlideIndex];
    const displayTime =
      getDisplayTimeByTemplate(nextSlide) ||
      getContentDurationByTemplate(nextSlide) ||
      this.defaultInterval;

    this.controlTemplates();
    this.nextSlideInterval$.next(displayTime);

    if (isLastSlide && this.dataToUpdate) {
      setTimeout(() => {
        if (this.dataToUpdate) {
          this.generateSlidesListInfo(this.dataToUpdate as TemplatesCarouselData);

          this.dataToUpdate = null;
        }
      }, Math.max(0, displayTime - this.delayBeforeUpdate));
    }
  }

  private generateSlidesListInfo(data: TemplatesCarouselData): void {
    const { templateList = [] } = data;

    this.carouselEmpty = !templateList?.length;

    this.templates$.next(templateList);

    this.nextSlideIndex = 0;
  }
}
