<div class="printable-template">
  <lib-cfs-template
    *ngIf="template?.generatedBody"
    class="w-100"
    [previewType]="TemplatePreviewType.Inactive"
    [template]="template"
    [forPrint]="true"
    (print)="onPrint()">
  </lib-cfs-template>
</div>
