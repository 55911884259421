import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HtmlTemplateDirective } from "./html-template.directive";
import { getAllKeyValueAssetParams, getResponsiveAssetsInfo, HtmlTemplateOptions, parseHtmlQrCodeContentAsset, ResponsiveAssetsInfo, TemplatePreviewOptions, TemplatePreviewType } from "clearline-common";
import { DigitalAssetGetDto, DigitalAssetScreenSize, KeyValueAsset, KeyValueDto } from "clearline-api";
import { BehaviorSubject, combineLatest } from "rxjs";
import { filter, map, take } from "rxjs/operators";

@Component({
  selector: "lib-html-template",
  standalone: true,
  imports: [CommonModule, HtmlTemplateDirective],
  templateUrl: "./html-template.component.html",
  styleUrls: ["./html-template.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HtmlTemplateComponent implements OnInit {
  @Input() screenSize: DigitalAssetScreenSize | null = DigitalAssetScreenSize.Full;
  @Input() previewType: TemplatePreviewType = TemplatePreviewType.Active;
  @Input() responsive = false;
  @Input() isLowResolutionScreen = false;
  @Input() isPrintView = false;

  @Input() set options(options: TemplatePreviewOptions | null) {
    if (options) {
      const assetsInfo: ResponsiveAssetsInfo = getResponsiveAssetsInfo(options);
      const assetItem: DigitalAssetGetDto | null | undefined = assetsInfo[DigitalAssetScreenSize.Full];
      const itemDefaultParams: KeyValueDto[] = assetItem?.configuration?.defaultParameters || [];
      const allParams: KeyValueAsset = getAllKeyValueAssetParams(options, itemDefaultParams);
      const htmlTemplateOptions: HtmlTemplateOptions = parseHtmlQrCodeContentAsset(allParams);

      this.options$.next(htmlTemplateOptions);
    }
  }

  @Output() ready = new EventEmitter();
  @Output() error = new EventEmitter<string>();

  readonly CFSPreviewType = TemplatePreviewType;
  readonly printBackgroundColor = "white";

  options$ = new BehaviorSubject<HtmlTemplateOptions>({});
  private logoLoaded$ = new BehaviorSubject<boolean>(false);
  private qrCodeLoaded$ = new BehaviorSubject<boolean>(false);
  private mainImageLoaded$ = new BehaviorSubject<boolean>(false);
  private fontLoaded$ = new BehaviorSubject<boolean>(false);
  private contentReady$ = combineLatest([this.logoLoaded$, this.qrCodeLoaded$, this.mainImageLoaded$, this.fontLoaded$]).pipe(
    map(([logoLoaded, qrCodeLoaded, mainImageLoaded, fontsLoaded]) => logoLoaded && qrCodeLoaded && mainImageLoaded && fontsLoaded),
    filter((contentReady) => contentReady),
    take(1)
  );

  ngOnInit(): void {
    this.contentReady$.subscribe(() => {
      this.ready.emit();
    });

    this.checkFontLoaded();
  }

  onLogoLoaded(): void {
    this.logoLoaded$.next(true);
  }

  onLogoError(): void {
    this.error.emit("Logo failed to load");
    this.logoLoaded$.next(true);
  }

  onQrCodeLoaded(): void {
    this.qrCodeLoaded$.next(true);
  }

  onQrCodeError(): void {
    this.error.emit("QR code failed to load");
    this.qrCodeLoaded$.next(true);
  }

  onMainImageLoaded(): void {
    this.mainImageLoaded$.next(true);
  }

  onMainImageError(): void {
    this.error.emit("Main image failed to load");
    this.mainImageLoaded$.next(true);
  }

  private checkFontLoaded(): void {
    document.fonts.load('1em "SF Pro"').then((fonts) => {
      if (fonts.length > 0) {
        this.fontLoaded$.next(true);
      } else {
        this.error.emit("SF Pro font failed to load");
        this.fontLoaded$.next(true);
      }
    }).catch(() => {
      this.error.emit("SF Pro font failed to load");
      this.fontLoaded$.next(true);
    });
  }
}
