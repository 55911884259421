import { Injectable } from "@angular/core";
import { DataService } from "@app/core";
import { SubscriptionAddons, SubscriptionPlan, SubscriptionPlanFilterPostDto, SubscriptionPlansWithAddonsGetDto } from "@app/models";
import { SubscriptionPlanType } from "clearline-api";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class SubscriptionPlanService {
  private serviceUrl = "SubscriptionPlan";
  constructor(private http: DataService) {}

  /** @deprecated */
  getListForNewRegistration(): Observable<SubscriptionPlan[]> {
    return this.http
      .get(`${this.serviceUrl}`)
      .pipe(map((x: SubscriptionPlan[]) => x.filter((o) => o.planType !== SubscriptionPlanType.ActivationBulk)));
  }

  /** Usage: https://clearline.atlassian.net/browse/PT-253?focusedCommentId=19055 */
  getFilteredPlans(filter: SubscriptionPlanFilterPostDto): Observable<SubscriptionPlan[]> {
    return this.http.post(`${this.serviceUrl}/filter`, filter);
  }

  getList(sourceType: SubscriptionPlanType): Observable<SubscriptionPlan[]> {
    return this.http.get(`${this.serviceUrl}/${sourceType}`);
  }

  getAllWithAddons(filter: SubscriptionPlanFilterPostDto): Observable<SubscriptionPlansWithAddonsGetDto> {
    return this.http.post(`${this.serviceUrl}/all`, filter);
  }

  getByProduct(productId: string): Observable<SubscriptionPlan> {
    return this.http.get(`${this.serviceUrl}/byProduct/${productId}`);
  }

  getLocationPlan(locationId: number): Observable<SubscriptionPlan> {
    return this.http.getRaw(`${this.serviceUrl}/locationPlan/${locationId}`);
  }

  getAddons(): Observable<SubscriptionAddons[]> {
    return this.http.get(`${this.serviceUrl}/addons`);
  }
}
