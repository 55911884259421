import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CustomHoverLinkDirective } from "@app/shared/directives/link-with-hover";
import { AdvancedSortableDirective } from "./advanced-sortable.directive";
import { NumbersOnlyDirective } from "./numbers-only.directive";
import { UrlOnlyDirective } from "./url-only.directive";

@NgModule({
  declarations: [NumbersOnlyDirective, UrlOnlyDirective, AdvancedSortableDirective, CustomHoverLinkDirective],
  imports: [CommonModule],
  exports: [NumbersOnlyDirective, UrlOnlyDirective, AdvancedSortableDirective, CustomHoverLinkDirective]
})
export class DirectivesModule {}
