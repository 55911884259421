import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IndustryLoyaltyCard, LoyaltyCard } from "@app/models";
import {
  LoyaltyCardType,
  LoyaltyCardTypes,
  loyaltyCardTypesSettings,
  SelectedLoyaltyProgramData
} from "../../../enroll-loyalty-widget-settings";

@Component({
  selector: "app-loyalty-program-type",
  templateUrl: "./loyalty-program-type.component.html",
  styleUrls: ["./loyalty-program-type.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoyaltyProgramTypeComponent implements OnInit {
  @Input() set industryList(list: IndustryLoyaltyCard[]) {
    if (list?.length) {
      this.industryLoyaltyCards = list;
      this.loadCardsByType();
      this.loading = false;
    }
  }

  @Output() selectCardType = new EventEmitter<LoyaltyCardType>();
  @Output() selectCard = new EventEmitter<SelectedLoyaltyProgramData>();

  LoyaltyCardTypes = LoyaltyCardTypes;
  selectedCard: LoyaltyCard | null = null;
  cardList: LoyaltyCard[] = [];
  selectedProgramTypeId = 0;
  loading = true;

  readonly cardTypesSetting = loyaltyCardTypesSettings.sort(this.compareNumbers);

  private industryLoyaltyCards: IndustryLoyaltyCard[] = [];

  ngOnInit(): void {
    this.selectedProgramTypeId = this.cardTypesSetting[0].id;
  }

  onProgramTypeChange(programType: LoyaltyCardType) {
    if (this.selectedProgramTypeId !== programType.id) {
      this.selectedProgramTypeId = programType.id;
      this.selectedCard = null;
      this.loadCardsByType();
      this.selectCardType.emit(programType);
    }
  }

  onLoyaltyCardChange(loyaltyCard: LoyaltyCard) {
    this.selectedCard = loyaltyCard;
    this.selectCard.emit({
      loyaltyCard,
      loyaltyType: this.selectedProgramTypeId
    });
  }

  private compareNumbers(a: LoyaltyCardType, b: LoyaltyCardType) {
    return a.sortOrder - b.sortOrder;
  }

  private loadCardsByType() {
    this.cardList = [];
    const cards = this.industryLoyaltyCards.filter((o) => o.loyaltyType === this.selectedProgramTypeId);
    for (let i = 0; i < cards.length; i++) {
      if (cards[i] && cards[i].loyaltyCard) {
        this.cardList.push(cards[i].loyaltyCard as LoyaltyCard);
      }
    }
  }
}
